import { AppBar, Button, Divider, Grid, IconButton, Paper, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bishopFormats } from '../../../constants';
import Alerts from "../../alerts/Alerts";
import MyAlert from "../../alerts/MyAlert";
import { ALERT_TYPE_WARNING } from "../../alerts/constants";
import { getVarCheck } from "../../checks/utility";
import { mainSelector } from "../../main/slice";
import { processTypesSelector } from "../../processTypes/slice";
import ProgressBarOneLine from "../../utility/ProgressBarOneLine";
import { customLabel } from '../../utility/customLabel';
import { secondsToHours } from "../../utility/vari";
import ButtonsDialog from "../buttons/ButtonsDialog";
import ChecksDialog from "../checks/ChecksDialog";
import ComponentDialog from "../components/ComponentDialog";
import ComponentsPage from "../components/ComponentsPage";
import MachineEventDialog from "../events/MachineEventDialog";
import MachineEventReasonDialog from "../events/MachineEventReasonDialog";
import UserEventDialog from "../events/UserEventDialog";
import UserEventsDialog from "../events/UserEventsDialog";
import FormStaplingProcesses from "../input/FormStaplingProcesses";
import {
  changeStaplingList, createStaplingAPI, disableFlagStopProcess, enableFlagButtons, enableFlagCloseChecks,
  enableFlagExtChecks,
  enableFlagMachineEventReason,
  enableFlagMachineEventType, enableFlagPauseChecks, enableFlagRestartChecks,
  enableFlagStartChecks, enableFlagStopProcess, enableFlagVars, operatorViewSelector, pauseStaplingAPI,
  presetVarCurrentValue,
  removeStaplingProcess,
  resetCurrentValue, startStaplingAPI, stopMachineEventAPI, stopStaplingAPI
} from "../slice";
import DialogOperator from "../utility/DialogOperator";
import ProgressLine from "../utility/ProgressLine";
import { MACHINE_EVENT_OFF } from "../utility/constants";
import { opViewStyles } from "../utility/customStyles";
import { renderProcessLabel } from "../utility/utility";
import VarsDialog from "../vars/VarsDialog";
import ReloadingStapling from "./ReloadingAPI";

const ManageStapling = ({ configuration }) => {
  const customClasses = opViewStyles();
  const dispatch = useDispatch();
  const { staplingList, machine, operator, odp, loading, startChecks, pauseChecks, restartChecks, closeChecks, errors, vars,
    machineEvent, flagDialog, extChecks, machineEventTypeReasons } = useSelector(operatorViewSelector);
  const { serverNotResponding } = useSelector(mainSelector);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [flagUpdate, setFlagUpdate] = React.useState(false);
  const { processType } = useSelector(processTypesSelector)
  let processTypeVar = processType?.totalPieces !== null ? processType.totalPieces : processType.validPieces

  //sposta il tab fra menu e documents
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  const createStapling = () => {
    dispatch(createStaplingAPI(staplingList, machine, operator));
  }

  const startStapling = () => {
    if (startChecks && startChecks.length > 0 && odp.startDate === null) {
      dispatch(enableFlagStartChecks());
      presetVarCheckValue(startChecks[0]);
    } else if (restartChecks && restartChecks.length > 0 && odp.startDate !== null) {
      dispatch(enableFlagRestartChecks());
      presetVarCheckValue(restartChecks[0]);
    } else if (!errors) {
      dispatch(startStaplingAPI(odp, machine, operator))
    }
  }

  const pauseStapling = () => {
    if (pauseChecks && pauseChecks.length > 0) {
      dispatch(enableFlagPauseChecks());
      presetVarCheckValue(pauseChecks[0]);
    } else if (!errors) {
      dispatch(pauseStaplingAPI(odp, machine, operator))
    }
  }

  const stopStapling = () => {
    if (closeChecks && closeChecks.length > 0) {
      dispatch(enableFlagCloseChecks());
      presetVarCheckValue(closeChecks[0]);
    } else {
      dispatch(stopStaplingAPI(odp, machine, operator))
    }
  }

  const changeProcessList = (p) => {
    if(odp){
      dispatch(removeStaplingProcess(odp, p, machine, operator))
    }else{
      dispatch(changeStaplingList(p))
    }
  }

  const renderProcesses = () => {
    if (staplingList && staplingList.length > 0) {
      return (
        staplingList.map((p) => {
          return (
            <TableRow key={p.id}>
              <TableCell colspan={2}>
                {renderProcessLabel(p, configuration)}
              </TableCell>
              <TableCell align='right'>
                <b>{configuration.showSingleOdp ? (p.validPieces !== null ? Number(p.validPieces.toFixed(configuration.decimals)) + '/' : p.totalPieces !== null ? Number((p.totalPieces - p.rejectedPieces).toFixed(configuration.decimals)) + '/' : 0 + '/') : null}{Number(p?.quantity.toFixed(configuration.decimals))} {p?.article?.measure}</b>
                <br />
                {p.validPieces !== null && p.quantity !== null && p.quantity > 0 && configuration.showSingleOdp ?
                  <ProgressLine variant="determinate"
                    value={(p.validPieces / p.quantity * 100) < 100 ? (p.validPieces / p.quantity * 100) : 100} /> :
                  p.validPieces === null && p.totalPieces !== null && p.quantity !== null && p.quantity > 0 && configuration.showSingleOdp ?
                    <ProgressLine variant="determinate"
                      value={(((p.totalPieces - p.rejectedPieces ?? 0) / p.quantity) * 100) < 100 ? (((p.totalPieces - p.rejectedPieces ?? 0) / p.quantity) * 100) : 100} />
                    : null}
              </TableCell>
              {!odp || configuration.enableUnstaple ? (
                <TableCell align="right">
                  <IconButton disabled={loading || staplingList.length<2} color="secondary"
                    onClick={() => changeProcessList(p)} >
                    <ClearIcon />
                  </IconButton>
                </TableCell >
              ) : null}
            </TableRow >
          )
        })
      )
    } else {
      return (
        <TableRow>
          <TableCell colSpan={3}>
            {customLabel("function.notAvailable").toUpperCase()}
          </TableCell>
        </TableRow>
      )
    }
  };

  const openUpdateDialog = () => {
    setFlagUpdate(true);
  };
  const closeUpdateDialog = () => {
    setFlagUpdate(false);
    dispatch(resetCurrentValue());
  };

  const renderUpdateForm = () => {
    return (
      <FormStaplingProcesses configuration={configuration} />
    )
  };

  const renderActionsForm = () => {
    return (
      <Button className={customClasses.dialogButton} variant="contained" onClick={closeUpdateDialog}
        color="secondary">{customLabel("button.close")}
      </Button>
    )
  }

  //API che cancella Evento macchina
  const stopMachineEvent = () => {
    dispatch(stopMachineEventAPI(machineEvent, machine));
  };

  const renderStopStaplingContentDialog = () => {
    return (
      <React.Fragment>
        {customLabel("operatorView.confirmStopStapling")}
      </React.Fragment>
    )
  };

  const renderStopStaplingActionsDialog = () => {
    return (
      <React.Fragment>
        <Button className={customClasses.dialogButton} variant="contained" disabled={loading} onClick={() => stopStapling()} color="primary">
          {customLabel("button.confirm")}
        </Button>
        <Button className={customClasses.dialogButton} variant="contained" onClick={() => dispatch(disableFlagStopProcess())} disabled={loading} color="secondary">
          {customLabel("button.cancel")}
        </Button>
      </React.Fragment>
    )
  }

  /**
   * Prevalorizza il currentValue ogniqualvolta vengono inizializzati i check
   * @param {object} check 
   */
  const presetVarCheckValue = (check) => {
    if (check.var_name !== "" && check.var_name !== null && vars) {
      let varToInsert = getVarCheck(check, vars);
      if (varToInsert) {
        dispatch(presetVarCurrentValue({ var: varToInsert }))
      }
    };
  }

  const availableButtons = (buttons) => {
    let showButtons = []
    for (let button in buttons) {
      let b = buttons[button]
      if (b.roles && operator.authorities && b.showInStapling) {
        for (let a in operator.authorities) {
          let p = operator.authorities[a]
          if (b.roles.some((x) => p === x.authority) && !showButtons.some((y) => b.id === y.id)) {
            showButtons.push(b)
          }
        }
      }
    }
    return showButtons
  }

  const renderProgressBar = () => {
    return (
      <div style={{ width: '250px', marginTop: '20px' }}>
        <ProgressBarOneLine total={odp !== null ? odp.quantity : null}
          current={odp !== null && odp.validPieces !== null ? odp.validPieces :
            odp !== null && odp.totalPieces !== null ? odp.totalPieces - odp.rejectedPieces : 0}
          measure={null} noMarginText={true} />
      </div>
    )
  }

  return (
    <Paper className={customClasses.middlePaper}>
      <AppBar position="static">
        <Tabs value={tabIndex} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
          <Tab label={customLabel("operatorView.stapling").toUpperCase()} key={"stapling"} value={0} />
          {configuration.enableComponents ? (
            <Tab label={customLabel("component.components")} key={"components"} value={1} />
          ) : null}
        </Tabs>
      </AppBar>
      {tabIndex === 0 ? (
        <React.Fragment>
          <Grid container alignItems="flex-start" justify="center">
            <Grid item xs={8}>
              <React.Fragment>
                <h3 className={customClasses.divTitleManagePages}>{customLabel("process.processes")}{staplingList && staplingList.length > 0 ? " (" + staplingList[0].name + ")" : null}</h3>
                <Divider />
              </React.Fragment>
              <Table size={"small"}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>{customLabel("operatorView.odpIdentifier")}</TableCell>
                    <TableCell align="right">{customLabel("operatorView.quantity")}</TableCell>
                    {!odp ? (
                      <TableCell align="right">
                        <IconButton disabled={loading} color="primary" onClick={openUpdateDialog}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    ) : configuration.enableUnstaple ? <TableCell align="right"></TableCell>:null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderProcesses()}
                </TableBody>
              </Table>
              <Table>
                <tbody>
                  {odp ? (
                    <React.Fragment>
                      <TableRow>
                        <TableCell>{customLabel("stapling.staplingStatus")}</TableCell>
                        <TableCell align='right'>
                          <b>{odp ? odp.status.name.toUpperCase() : customLabel("function.notAvailable").toUpperCase()}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{customLabel("stapling.quantity")}</TableCell>
                        <TableCell align='right'>
                          {odp && odp.quantity !== null && staplingList && staplingList.length > 0 && staplingList[0].article.id !== null ?
                            (<b>{odp.validPieces !== null ? Number(odp.validPieces.toFixed(configuration.decimals)) : odp.totalPieces !== null ? Number((odp.totalPieces - odp.rejectedPieces).toFixed(configuration.decimals)) : 0}/{Number(odp.quantity.toFixed(configuration.decimals))} {staplingList[0].article.measure}</b>) : null}
                          <br />
                          {odp.validPieces !== null && odp.quantity !== null && odp.quantity > 0 ?
                            <ProgressLine variant="determinate"
                              value={(odp.validPieces / odp.quantity * 100) < 100 ? (odp.validPieces / odp.quantity * 100) : 100} /> :
                            odp.validPieces === null && odp.totalPieces !== null && odp.quantity !== null && odp.quantity > 0 ?
                              <ProgressLine variant="determinate"
                                value={(((odp.totalPieces - odp.rejectedPieces) / odp.quantity) * 100) < 100 ? (((odp.totalPieces - odp.rejectedPieces) / odp.quantity) * 100) : 100} />
                              : null}
                        </TableCell>
                      </TableRow>
                      {configuration.showOdpRemainingQuantity ? (
                        <TableRow>
                          <TableCell>{customLabel("operatorView.remainingQuantity")}</TableCell>
                          <TableCell align="right"><b>{odp.validPieces !== null && odp.quantity > odp.validPieces ? Number((odp.quantity - odp.validPieces).toFixed(configuration.decimals))
                            : odp.totalPieces !== null && odp.quantity > (odp.totalPieces - odp.rejectedPieces) ? Number((odp.quantity - (odp.totalPieces - odp.rejectedPieces)).toFixed(configuration.decimals))
                              : (odp.validPieces !== null && odp.validPieces >= odp.quantity) || (odp.totalPieces !== null && (odp.totalPieces - odp.rejectedPieces) >= odp.quantity) ? 0
                                : Number(odp.quantity.toFixed(configuration.decimals))} {staplingList[0].article.measure}</b>
                          </TableCell>
                        </TableRow>
                      ) : null}
                      <TableRow>
                        <TableCell>{customLabel("odp.startDate")}</TableCell>
                        <TableCell align='right'>
                          <b> {odp && odp.startDate !== null ? moment(odp.startDate).format(bishopFormats.LTS) : null}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{customLabel("odp.endDate")}</TableCell>
                        <TableCell align='right'>
                          <b>{odp && odp.endDate ? moment(odp.endDate).format(bishopFormats.LTS) : null}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{customLabel("operatorView.expectedRunTime")}</TableCell>
                        <TableCell align='right' l><b>{odp.expectedRunTime ? secondsToHours(odp.expectedRunTime) : null}</b></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{customLabel("operatorView.expectedSetupTime")}</TableCell>
                        <TableCell align='right'><b>{odp.expectedSetupTime ? secondsToHours(odp.expectedSetupTime) : null}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{customLabel("machineEvent.machineEvent")}</TableCell>
                        <TableCell align='right'>{machineEvent && (!machineEvent.type.systemEvent || machineEvent.type.name === MACHINE_EVENT_OFF) ? (<b>{machineEvent.type.name}</b>) : null}</TableCell>
                      </TableRow>
                    </React.Fragment>
                  ) : null}
                </tbody>
              </Table>
            </Grid>
            <Grid item xs={4}>
              <Table size={"small"}>
                <tbody>
                  <TableRow>
                    <TableCell>
                      <Button variant="contained" color="primary" className={customClasses.actionButtonManagePages} fullWidth
                        disabled={loading || odp !== false || !staplingList || staplingList.length < 2 || serverNotResponding}
                        onClick={createStapling}>
                        {customLabel("button.stapling")}
                      </Button>
                      <Button variant="contained" color="primary" className={customClasses.actionButtonManagePages} fullWidth
                        disabled={loading || !odp || (odp && odp.status.id === 1) || (odp && odp.status.id === 4) || (machineEvent && !machineEvent.type.systemEvent) || serverNotResponding}
                        onClick={startStapling}>
                        {customLabel("button.start")}
                      </Button>
                      <Button variant="contained" color="secondary" className={customClasses.actionButtonManagePages} fullWidth
                        disabled={loading || !odp || (odp && odp.status.id >= 2) || (machineEvent && !machineEvent.type.systemEvent) || serverNotResponding}
                        onClick={() => pauseStapling()}>
                        {customLabel("button.pause")}
                      </Button>
                      <Button variant="contained" color="secondary" className={customClasses.actionButtonManagePages} fullWidth
                        disabled={loading || !odp || (odp && odp.status.id >= 4) || (machineEvent && !machineEvent.type.systemEvent) || serverNotResponding}
                        onClick={() => dispatch(enableFlagStopProcess())}>
                        {customLabel("button.stop")}
                      </Button>
                    </TableCell>
                  </TableRow>
                  {odp ? (
                    <TableRow>
                      <TableCell>
                        <Button variant="contained" color="primary" fullWidth className={customClasses.actionButtonManagePages}
                          onClick={() => dispatch(enableFlagMachineEventType())}
                          disabled={loading || (machineEvent && !machineEvent.type.systemEvent) || odp.status.id >= 4 || serverNotResponding}
                        >
                          {customLabel("button.startEvent")}
                        </Button>
                        <Button variant="contained" color="secondary" className={customClasses.actionButtonManagePages} fullWidth
                          onClick={() => stopMachineEvent()}
                          disabled={loading || !machineEvent || machineEvent.type.systemEvent || odp.status.id >= 4 || serverNotResponding}
                        >
                          {customLabel("button.stopEvent")}
                        </Button>
                        <Button variant="contained" color="secondary" className={customClasses.actionButtonManagePages} fullWidth
                          onClick={() => dispatch(enableFlagMachineEventReason())}
                          disabled={loading || !machineEvent || !machineEventTypeReasons || machineEventTypeReasons.length === 0 || odp.status.id >= 4 || serverNotResponding}
                        >
                          {customLabel("button.justifyEvent")}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {vars && vars.length > 0 ? (
                    <TableRow>
                      <TableCell>
                        <Button variant="contained" color="primary" fullWidth className={customClasses.actionButtonManagePages}
                          disabled={loading || odp.startDate == null || odp.endDate != null || serverNotResponding} onClick={() => dispatch(enableFlagVars())}
                        >
                          {customLabel("button.declare")}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {availableButtons(staplingList[0].buttons).length > 0 ? (
                    <TableRow>
                      <TableCell>
                        <Button variant="contained"
                          className={customClasses.actionButtonManagePages}
                          color="primary" fullWidth onClick={() => dispatch(enableFlagButtons())} disabled={loading || odp.endDate != null || serverNotResponding}
                        >
                          {customLabel("button.run")}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </tbody>
              </Table>
            </Grid>
          </Grid>
          {configuration && !configuration.blockingChecks && extChecks && extChecks.length > 0 ?
            <MyAlert key={customLabel("button.insert") + "_" + extChecks[0].name} name={null} message={customLabel("operatorView.onDemandChecksWaitingForValue")}
              type={ALERT_TYPE_WARNING} interaction={true} onCloseAlert={null}
              onClickAlert={() => dispatch(enableFlagExtChecks())} buttonText={customLabel("button.insert")} /> : null}
          <Alerts />
        </React.Fragment>
      ) : tabIndex === 1 ? (
        <ComponentsPage configuration={configuration} operatorView={true} />
      ) : null}
      <DialogOperator flag={flagUpdate} title={customLabel("operatorView.updateStapling")} form={renderUpdateForm}
        actions={renderActionsForm} transparentBackround={false} />
      <ChecksDialog presetVarCheckValue={presetVarCheckValue} configuration={configuration} processTypeVar={processTypeVar} renderProgressBar={renderProgressBar} />
      <VarsDialog configuration={configuration} processTypeVar={processTypeVar} renderProgressBar={renderProgressBar} />
      <ReloadingStapling presetVarCheckValue={presetVarCheckValue} configuration={configuration} />
      <UserEventDialog existChecks={false} onConfirm={null} />
      <MachineEventDialog />
      <UserEventsDialog/>
      <MachineEventReasonDialog configuration={configuration} />
      {/*       <DialogOperator flag={flagDialog.flagChoosePause} title={customLabel("operatorView.choosePauseProcess")} form={renderPauseStaplingContentDialog}
        actions={renderPauseStaplingActionsDialog} transparentBackround={false} /> */}
      <DialogOperator flag={flagDialog.flagStopProcess} title={customLabel("function.confirm")} form={renderStopStaplingContentDialog}
        actions={renderStopStaplingActionsDialog} transparentBackround={false} />
      <ComponentDialog configuration={configuration} />
      <ButtonsDialog buttons={availableButtons(staplingList[0].buttons)} />
    </Paper>
  );
}

export default ManageStapling;