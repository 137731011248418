import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  operatorViewSelector, disableFlagChecks, changeCurrentValue, insertChecksAPI, changeNoteChecks, insertStaplingChecksAPI,
  enableFlagInsertCheck, disableFlagExtChecks, disableFlagInsertCheck, setArticleToWaste, insertVarsAPI, insertStaplingVarsAPI,
  loadLotListAPI, setNewConsum, setEditConsum, loadScrapListAPI, loadSubstituteListAPI
} from "../slice";
import { customLabel } from '../../utility/customLabel';
import { Button } from "@material-ui/core";
import DialogOperator from "../utility/DialogOperator";
import { renderFormInsertCheck, renderExtChecksToInsert, getVarCheck } from "../../checks/utility";
import { opViewStyles } from "../utility/customStyles";

// il componente viene chiamato solamente se process.checks esiste e length > 0
const ChecksDialog = ({ presetVarCheckValue, configuration, processTypeVar, renderProgressBar }) => {
  const customClasses = opViewStyles();
  const dispatch = useDispatch();
  const { startChecks, closeChecks, pauseChecks, restartChecks, flagDialog, process, currentValue, odp,
    operator, noteChecks, loading, modeStapling, extChecks, modeMaintenance, choosenCheck, vars, machine, staplingArticles,
    articleToWaste, staplingList, components, lotRequiredComponents, endDeclaration
  } = useSelector(operatorViewSelector);

  let finalComponents = process.autoComponentConsumption ? lotRequiredComponents : components

  //viene valorizzato lo stato currentValue che rappresenta il valore del check numerico o stringa
  const handleChangeValueText = (event, id) => {
    dispatch(changeCurrentValue({ value: event.target.value }));
  };
  //viene valorizzato lo stato currentValue che rappresenta il valore del check booleano
  const handleChangeBooleanCheck = (event, value) => {
    dispatch(changeCurrentValue({ value: value }));
  }
  //viene valorizzato lo stato noteChecks che rappresenta il valore dellle note del checks selezionato
  const handleChangeNoteCheck = (event) => {
    dispatch(changeNoteChecks({ value: event.target.value }));
  }
  //bottone che manda al API il check valorizzato e chiude il dialog
  const handleConfirmCheck = (event, checkType) => {
    let checkId;
    let varToInsert = false;
    let currentChecks = false;
    switch (checkType) {
      case "start": {
        currentChecks = [...startChecks];
        break;
      }
      case "pause": {
        currentChecks = [...pauseChecks];
        break;
      }
      case "restart": {
        currentChecks = [...restartChecks];
        break;
      }
      case "close": {
        currentChecks = [...closeChecks];
        break;
      }
      case "onDemand": {
        currentChecks = [...extChecks];
        break;
      }
      default: return;
    }
    checkId = currentChecks[0].id;
    if (currentChecks && currentChecks[0].var_name !== "" && currentChecks[0].var_name !== null) {
      varToInsert = getVarCheck(currentChecks[0], vars);
    };
    //timestamp false in quanto viene eliminato il primo check in lista
    if (!modeStapling) {
      if (!varToInsert || (varToInsert && endDeclaration)) {
        dispatch(insertChecksAPI(odp, process, checkId, currentValue, operator, machine, checkType, noteChecks, modeMaintenance, false));
      } else if (varToInsert && !endDeclaration) {
          dispatch(insertVarsAPI(odp, process, varToInsert, currentValue, operator, machine, true, checkId, checkType, false))
      }
    } else {
        if (!varToInsert || (varToInsert && endDeclaration)) {
          dispatch(insertStaplingChecksAPI(odp, checkId, currentValue, operator, machine, checkType, noteChecks, false))
        } else if (varToInsert && !endDeclaration) {
            dispatch(insertStaplingVarsAPI(odp, varToInsert, currentValue, operator, machine, articleToWaste, true, checkId, checkType, false))
        }
    }
  };

  //bottone che chiude il dialog e annulla l'inserimento del check
  const handleCancelCheck = () => {
    dispatch(disableFlagChecks());
  };

  /**
   * Bottoni dialog actions
   * @param {string} type 
   * @param {boolean} maintenance 
   * @returns 
   */
  const renderDialogActions = (type, maintenance) => {
    // if (!maintenance) {
    return (
      <React.Fragment>
        <Button className={customClasses.dialogButton} variant="contained" onClick={(e) => handleConfirmCheck(e, type)} color="primary"
          disabled={currentValue === null || currentValue === "" || loading}>
          {customLabel("button.confirm")}
        </Button>
        {type !== "onDemand" ? (
          <Button className={customClasses.dialogButton} variant="contained" onClick={handleCancelCheck} disabled={loading}
            color="secondary">
            {customLabel("button.close")}
          </Button>
        ) : null}
      </React.Fragment>
    )
  }
  /**
   * prevalorizza il check scelto fra quelli onDemand non bloccanti, da inserire
   * @param {object} checkChoosen 
   */
  const handleChooseCheck = (checkChoosen) => {
    dispatch(enableFlagInsertCheck(checkChoosen));
    if (presetVarCheckValue) {
      presetVarCheckValue(checkChoosen);
    }
  }

  const renderListChecksActions = () => {
    return (
      <Button className={customClasses.dialogButton} disabled={loading} variant="contained" onClick={() => dispatch(disableFlagExtChecks())} color="secondary">
        {customLabel("button.close")}
      </Button>
    )
  }
  const handleConfirmNotBlockCheck = () => {
    let checkType = "onDemand";
    let varToInsert = false;
    if (choosenCheck && choosenCheck.var_name !== "" && choosenCheck.var_name !== null) {
      varToInsert = getVarCheck(choosenCheck, vars);
    };
    if (!modeStapling) {
      if (!varToInsert || (varToInsert && endDeclaration)) {
        dispatch(insertChecksAPI(odp, process, choosenCheck.id, currentValue, operator, machine, checkType, noteChecks, modeMaintenance, choosenCheck.timeReceived))
      } else if (varToInsert && !endDeclaration) {
          dispatch(insertVarsAPI(odp, process, varToInsert, currentValue, operator, machine, true, choosenCheck.id, checkType, choosenCheck.timeReceived))
      }
    } else {
        if (!varToInsert || (varToInsert && endDeclaration)) {
          dispatch(insertStaplingChecksAPI(odp, choosenCheck.id, currentValue, operator, machine, checkType, noteChecks, choosenCheck.timeReceived))
        } else if (varToInsert && !endDeclaration) {
            dispatch(insertStaplingVarsAPI(odp, varToInsert, currentValue, operator, machine, articleToWaste, true, choosenCheck.id, checkType, choosenCheck.timeReceived))
        }
    }
  }
  const renderInsertCheckActions = () => {
    return (
      <React.Fragment>
        <Button className={customClasses.dialogButton} variant="contained" onClick={handleConfirmNotBlockCheck}
          color="primary" disabled={currentValue === null || currentValue === "" || loading}>
          {customLabel("button.confirm")}
        </Button>
        <Button className={customClasses.dialogButton} disabled={loading} variant="contained" onClick={() => dispatch(disableFlagInsertCheck())}
          color="secondary">{customLabel("button.back")}</Button>
      </React.Fragment>
    )
  }

  const onChangeArticleAutocomplete = (name, value, reason) => {
    if (reason === "clear") {
      dispatch(setArticleToWaste({ articles: false, value: false }))
    } else {
      dispatch(setArticleToWaste({ articles: staplingArticles, value: value.id }))
    }
  };

  const declareNewComp = (compId, waste, substitute) => {
    let consumedQuantity
    let index = finalComponents.findIndex(c => c.id === compId)
    if (index !== -1) {
      consumedQuantity = finalComponents[index].consumedQuantity
    }
    let totalPieces = 0;
    let autoComponentConsumption
    if (modeStapling) {
      autoComponentConsumption = odp.staplingProcesses.every(obj => obj.autoComponentConsumption)
      if (odp.validPieces !== null) {
        totalPieces = odp.validPieces;
      } else if (odp.validPieces === null && odp.totalPieces !== null) {
        totalPieces = odp.totalPieces;
      }
    }
    if (!modeStapling) {
      autoComponentConsumption = process.autoComponentConsumption
      if (process.validPieces !== null) {
        totalPieces = process.validPieces;
      } else if (process.validPieces === null && process.totalPieces !== null) {
        totalPieces = process.totalPieces;
      }
    }
    dispatch(loadLotListAPI(odp, process, compId, odp.stapling));
    if (configuration.scrapReasonComponents) {
      dispatch(loadScrapListAPI(odp, process, compId, odp.stapling));
    }
    if (configuration.changeComponent) {
      dispatch(loadSubstituteListAPI(odp, process, compId, odp.stapling));
    }
    dispatch(setNewConsum({ compId: compId, components: finalComponents, decimals: configuration.componentDecimals, waste: waste, substitute: substitute, totalPieces: totalPieces, autoComponentConsumption: autoComponentConsumption, consumedQuantity: consumedQuantity, useAlwaysFactor: configuration.componentUseAlwaysFactor }));
  }

  const declareEditComponent = (compId, consumId) => {
    dispatch(loadLotListAPI(odp, process, compId, odp.stapling));
    dispatch(setEditConsum({ compId: compId, consumId: consumId, components: finalComponents }))
  }

  const finishDeclaration = () => {
    dispatch(changeCurrentValue({ value: false, endDeclaration: true }));
  }

  return (
    <React.Fragment className={customClasses.paddingLabelInput}>
      {/*Check normali */}
      <DialogOperator flag={flagDialog.flagStart && startChecks && startChecks.length > 0 && !flagDialog.flagCompConsum && !flagDialog.flagCompWaste ? true : false} title={customLabel("operatorView.startChecks")}
        form={() => renderFormInsertCheck(startChecks, false, false, currentValue, handleChangeBooleanCheck, handleChangeValueText, noteChecks, handleChangeNoteCheck, loading, vars, modeStapling ? staplingArticles : false, articleToWaste, onChangeArticleAutocomplete, modeStapling ? staplingList : false,
          finalComponents, declareNewComp, declareEditComponent, finishDeclaration, endDeclaration, configuration, processTypeVar, renderProgressBar)}
        actions={() => renderDialogActions("start", modeMaintenance)} transparentBackround={false} check={startChecks ? startChecks[0] : null} />
      <DialogOperator flag={flagDialog.flagClose && closeChecks && closeChecks.length > 0 && !flagDialog.flagCompConsum && !flagDialog.flagCompWaste ? true : false} title={customLabel("operatorView.closeChecks")}
        form={() => renderFormInsertCheck(closeChecks, false, false, currentValue, handleChangeBooleanCheck, handleChangeValueText, noteChecks, handleChangeNoteCheck, loading, vars, modeStapling ? staplingArticles : false, articleToWaste, onChangeArticleAutocomplete, modeStapling ? staplingList : false,
          finalComponents, declareNewComp, declareEditComponent, finishDeclaration, endDeclaration, configuration, processTypeVar, renderProgressBar)}
        actions={() => renderDialogActions("close", modeMaintenance)} transparentBackround={false} check={closeChecks ? closeChecks[0] : null} />
      <DialogOperator flag={flagDialog.flagPause && pauseChecks && pauseChecks.length > 0 && !flagDialog.flagCompConsum && !flagDialog.flagCompWaste ? true : false} title={customLabel("operatorView.pauseChecks")}
        form={() => renderFormInsertCheck(pauseChecks, false, false, currentValue, handleChangeBooleanCheck, handleChangeValueText, noteChecks, handleChangeNoteCheck, loading, vars, modeStapling ? staplingArticles : false, articleToWaste, onChangeArticleAutocomplete, modeStapling ? staplingList : false,
          finalComponents, declareNewComp, declareEditComponent, finishDeclaration, endDeclaration, configuration, processTypeVar, renderProgressBar)}
        actions={() => renderDialogActions("pause", modeMaintenance)} transparentBackround={false} check={pauseChecks ? pauseChecks[0] : null} />
      <DialogOperator flag={flagDialog.flagRestart && restartChecks && restartChecks.length > 0 && !flagDialog.flagCompConsum && !flagDialog.flagCompWaste ? true : false} title={customLabel("operatorView.restartChecks")}
        form={() => renderFormInsertCheck(restartChecks, false, false, currentValue, handleChangeBooleanCheck, handleChangeValueText, noteChecks, handleChangeNoteCheck, loading, vars, modeStapling ? staplingArticles : false, articleToWaste, onChangeArticleAutocomplete, modeStapling ? staplingList : false,
          finalComponents, declareNewComp, declareEditComponent, finishDeclaration, endDeclaration, configuration, processTypeVar, renderProgressBar)}
        actions={() => renderDialogActions("restart", modeMaintenance)} transparentBackround={false} check={restartChecks ? restartChecks[0] : null} />
      {/*Check a richiesta bloccanti */}
      <DialogOperator flag={flagDialog.flagOnDemand && extChecks.length > 0 && !flagDialog.flagCompConsum && !flagDialog.flagCompWaste ? true : false}
        title={customLabel("operatorView.onDemandChecks")}
        form={() => renderFormInsertCheck(extChecks, true, false, currentValue, handleChangeBooleanCheck, handleChangeValueText, noteChecks, handleChangeNoteCheck, loading, vars, modeStapling ? staplingArticles : false, articleToWaste, onChangeArticleAutocomplete, modeStapling ? staplingList : false,
          finalComponents, declareNewComp, declareEditComponent, finishDeclaration, endDeclaration, configuration, processTypeVar, renderProgressBar)}
        actions={() => renderDialogActions("onDemand", modeMaintenance)} transparentBackround={false} check={extChecks ? extChecks[0] : null} />
      {/*Elenco check a richiesta  non bloccanti da inserire */}
      <DialogOperator flag={flagDialog.flagExtChecks && !flagDialog.flagCompConsum && !flagDialog.flagCompWaste ? true : false}
        title={customLabel("operatorView.listOnDemandChecks")}
        form={() => renderExtChecksToInsert(extChecks, handleChooseCheck)} actions={() => renderListChecksActions()} transparentBackround={false} />
      {/*Check a richiesta scelto non bloccante da inserire */}
      <DialogOperator flag={flagDialog.flagInsertCheck && !flagDialog.flagCompConsum && !flagDialog.flagCompWaste ? true : false}
        title={customLabel("processType.check.insertCheck")}
        form={() => renderFormInsertCheck(false, true, choosenCheck, currentValue, handleChangeBooleanCheck, handleChangeValueText, noteChecks, handleChangeNoteCheck, loading, vars, modeStapling ? staplingArticles : false, articleToWaste, onChangeArticleAutocomplete, modeStapling ? staplingList : false,
          finalComponents, declareNewComp, declareEditComponent, finishDeclaration, endDeclaration, configuration, processTypeVar, renderProgressBar)}
        actions={() => renderInsertCheckActions()} transparentBackround={false} />
    </React.Fragment>
  );
}

export default ChecksDialog;