import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWebSocket from 'react-use-websocket';
import useInterval from 'react-useinterval';
import { WEBSOCKET_URL_CHECKS, WEBSOCKET_URL_ERROR } from '../../../constants';
import { ALERT_PRODUCED_QUANTITY, ALERT_TYPE_WARNING } from '../../alerts/constants';
import { addAlert, alertsSelector, resetState as resetAlertsState } from "../../alerts/slice";
import { checksSelector } from "../../checks/slice";
import { useBoolean } from "../../customHooks/useBoolean";
import { showSocketError } from "../../notifications/slice";
import { getAPI as getProcessTypeAPI } from "../../processTypes/slice";
import { splitSelector } from "../../split/slice";
import { customLabel } from '../../utility/customLabel';
import { varsSelector } from '../../vars/slice';
import { useSocketOnce } from "../customHooks/useSocketOnce";
import {
    addExtCheck, disableCheckAutostart, enableFlagOnDemand, getComponentsAPI, getMachineEventTypesAPI, getMachineVarsAPI,
    getOdpUserEventAPI,
    getProcessChecksAPI,
    getProcessVarsAPI,
    getUserEventTypesAPI, operatorViewSelector, pauseProcessAPI, reloadingInterfaceOdpModeAPI, startProcessAPI, stopProcessAPI
} from "../slice";
import { socketOptions } from "../utility/constants";

const ReloadingAPI = ({ configuration, handleOpenUserEvent, handleAddProcess, presetVarCheckValue }) => {
    const dispatch = useDispatch();
    const { process, odp, machine, operator, userEventTypes, userEventType, checkAutostart,
        startChecks, closeChecks, restartChecks, pauseChecks, errors,
        loading, interfaceNeedUpdate, reloading, modeMaintenance, vars, unmountProcess, flagDialog, extChecks, choosenUserEvent
    } = useSelector(operatorViewSelector);
    const { splitDialog } = useSelector(splitSelector);
    const { varToSee } = useSelector(varsSelector);
    const { checkToSee } = useSelector(checksSelector);
    const { closedAlerts } = useSelector(alertsSelector);
    const { lastMessage } = useWebSocket(WEBSOCKET_URL_ERROR, socketOptions);
    const { lastMessage: lastCheck } = useWebSocket(WEBSOCKET_URL_CHECKS, socketOptions);
    const [machineEventsCall, { setTrue: setMacEventsTrue }] = useBoolean(false);
    const [userEventsCall, { setTrue: setUsEventsTrue }] = useBoolean(false);
    const [varsCall, { setTrue: setVarsTrue }] = useBoolean(false);
    const [checksCall, { setTrue: setChecksTrue }] = useBoolean(false);
    const [machineVarsCall, { setTrue: setMacVarsTrue }] = useBoolean(false);
    const [componentsCall, { setTrue: setComponentsTrue }] = useBoolean(false);
    const [processTypeCall, { setTrue: setProcessTypeTrue }] = useBoolean(false);

    useInterval(() => updatingInterface(), 10000);

    //WS errori
    const socketErrorCallback = (msg) => {
        let j = JSON.parse(msg.data);
        if (j.process !== null && process !== null && j.process.toString() === process.id.toString() && j.error) {
            dispatch(showSocketError(j.error));
        }
    }
    useSocketOnce(lastMessage, process, socketErrorCallback);

    // WS checks
    const socketCheckCallback = (msg) => {
        let j = JSON.parse(msg.data);
        console.log(j)
        if (j.SUBWRAPPERID && j.SUBWRAPPERID.toString() === process.id.toString() && j.SUBWRAPPERTYPE === process.type.code) {
            dispatch(addExtCheck({ name: j.MESSAGENAME, timestamp: j.MESSAGETIMESTAMP }))
        }
    }
    useSocketOnce(lastCheck, process, socketCheckCallback);

    //checks onDemand
    useEffect(() => {
        if (!Object.values(flagDialog).includes(true) && extChecks.length > 0 && !splitDialog && !checkToSee && !varToSee && configuration.blockingChecks) {
            dispatch(enableFlagOnDemand());
            presetVarCheckValue(extChecks[0]);
        }
    }, [dispatch, extChecks, flagDialog, splitDialog, varToSee, checkToSee, configuration.blockingChecks, presetVarCheckValue])

    //faccio il reset degli alerts
    useEffect(() => {
        dispatch(resetAlertsState());
    }, [dispatch])
    //creo alert per quantità prodotta
    useEffect(() => {
        if ((process.quantity !== null && process.validPieces !== null && process.quantity <= process.validPieces) ||
            (process.quantity !== null && process.totalPieces !== null && process.quantity <= process.totalPieces)) {
            if (!closedAlerts.includes("producedQuantity")) {
                dispatch(addAlert({ name: ALERT_PRODUCED_QUANTITY, type: ALERT_TYPE_WARNING, message: customLabel("alert.producedQuantity") }));
            }
        }
    }, [dispatch, process, closedAlerts]);

    useEffect(() => {
        //API per start close pause process ogni qualvolta i relativi checks vengono azzerati
        if (flagDialog.flagStart && startChecks && startChecks.length === 0 && !errors) {
            dispatch(startProcessAPI(odp, process, operator, machine, choosenUserEvent));
        };
        if (flagDialog.flagClose && closeChecks && closeChecks.length === 0 && !errors) {
            dispatch(stopProcessAPI(odp, process, operator, machine));
        };
        if (flagDialog.flagPause && pauseChecks && pauseChecks.length === 0 && !errors) {
            dispatch(pauseProcessAPI(odp, process, operator, machine, unmountProcess));
        };
        if (flagDialog.flagRestart && restartChecks && restartChecks.length === 0 && !errors) {
            dispatch(startProcessAPI(odp, process, operator, machine, choosenUserEvent));
        };
    }, [dispatch, flagDialog, startChecks, pauseChecks, restartChecks, closeChecks, errors, odp, process,
        machine, operator, unmountProcess, choosenUserEvent]);

    //carico componenti prima volta
    useEffect(() => {
        if (!componentsCall) {
            if(configuration.enableComponents){
                dispatch(getComponentsAPI(odp, process, configuration.retrieveLotInfo,true));
            }
            setComponentsTrue()
        };
    }, [dispatch, setComponentsTrue, odp, process, componentsCall, configuration.retrieveLotInfo,configuration.enableComponents])

    //carico gli eventi macchina prima volta
    useEffect(() => {
        if (!machineEventsCall) {
            dispatch(getMachineEventTypesAPI(machine));
            setMacEventsTrue()
        };
    }, [dispatch, setMacEventsTrue, machine, machineEventsCall])

    //carico eventi utente prima volta
    useEffect(() => {
        if (!userEventsCall) {
            dispatch(getOdpUserEventAPI(odp, process, operator))
            dispatch(getUserEventTypesAPI(operator, process, odp, configuration.enableComponents, configuration.retrieveLotInfo));
            setUsEventsTrue()
        };
    }, [dispatch, userEventsCall, operator, process, odp, configuration.enableComponents, setUsEventsTrue, configuration.retrieveLotInfo, configuration.singleUserEvent])

    //carico checks prima volta
    useEffect(() => {
        if (!checksCall) {
            dispatch(getProcessChecksAPI(odp, process));
            setChecksTrue();
        }
    }, [dispatch, checksCall, odp, process, setChecksTrue])

    //carico vars prima volta
    useEffect(() => {
        if (!varsCall) {
            dispatch(getProcessVarsAPI(process));
            setVarsTrue()
        }
    }, [dispatch, varsCall, process, setVarsTrue])

    //carico machineVars prima volta
    useEffect(() => {
        if (!machineVarsCall) {
            dispatch(getMachineVarsAPI(machine));
            setMacVarsTrue()
        }
    }, [dispatch, machineVarsCall, machine, setMacVarsTrue])

    useEffect(() => {
        if (!processTypeCall) {
            dispatch(getProcessTypeAPI(process.type.id));
            setProcessTypeTrue()
        }
    }, [dispatch, processTypeCall, process, setProcessTypeTrue])

    //una volta caricati i gli eventi utente verifica se la lavorazione sia in autostart
    useEffect(() => {
        //avvio della lavorazione in automatico nel caso abbia autostart:true e lavorazione ancora nn avviata
        if (process.type.autostart && process.status.id === 5 && !loading && checkAutostart && userEventTypes && !errors && startChecks && vars) {
            dispatch(disableCheckAutostart());
            if (!userEventType && configuration.askUserEvent) {
                handleOpenUserEvent()
            } else {
                handleAddProcess()
            }
        }
        //avvio della lavorazione in automatico nel caso abbia autostart:true e lavorazione in pausa
        if (process.type.autostart && process.status.id === 3 && !loading && userEventTypes && checkAutostart && !errors && restartChecks && vars) {
            dispatch(disableCheckAutostart());
            if (!userEventType && configuration.askUserEvent) {
                handleOpenUserEvent()
            } else {
                handleAddProcess()
            }
        }

    }, [dispatch, process, userEventTypes, checkAutostart, loading, restartChecks, userEventType, startChecks, errors, machine, operator, odp,
        modeMaintenance, configuration, handleAddProcess, handleOpenUserEvent, vars]);

    //unico reload in caso di errori o di interazione con interfaccia
    useEffect(() => {
        if (interfaceNeedUpdate && !loading && !reloading && !errors) {
            const timeout = setTimeout(() => {
                dispatch(reloadingInterfaceOdpModeAPI(odp, process, machine, operator, configuration.enableFreeLotComponents, false, modeMaintenance, true, configuration.retrieveLotInfo));
            }, 2000)
            return () => clearTimeout(timeout)
        };
    }, [dispatch, interfaceNeedUpdate, loading, reloading, errors, odp, process, machine, operator, configuration, modeMaintenance])


    //metodo che aggiornano i dati al interno del mes  
    const updatingInterface = () => {
        if (!reloading && !loading &&
            !flagDialog.flagStart &&
            !flagDialog.flagRestart &&
            !flagDialog.flagClose &&
            !flagDialog.flagPause &&
            !flagDialog.flagOnDemand &&
            !flagDialog.flagUserEventType &&
            !flagDialog.flagMachineEventType &&
            //!flagDialog.flagMachineEventReason &&
            !flagDialog.flagButtons &&
            !flagDialog.flagInsertButton &&
            !flagDialog.flagStopProcess &&
            !flagDialog.flagChoosePause &&
            !flagDialog.flagCompConsum &&
            !flagDialog.flagCompWaste
        ) {
            dispatch(reloadingInterfaceOdpModeAPI(odp, process, machine, operator, configuration.enableComponents, true, modeMaintenance, true, configuration.retrieveLotInfo));
        }
    };

    return (
        null
    );
}

export default ReloadingAPI;